import React from 'react';
import { ButtonStyle, Narrow, WhiteButton } from './Button.module.scss';

const Button = (props) => {
  const btnClass = {
    black: 'blackBckg',
    white: [WhiteButton, 'blackCol'].join(' '),
  } ;

  const btnType = {
    narrow: Narrow
  }
  // debugger;
  const modifiedClassName = `${props.children.props.className || ''} ${ButtonStyle} ` +
                            `${btnClass[props.color]} ${props.className || ''} ` +
                            `${props.type ? btnType[props.type] : ''}`;
  
  const modifiedChild = React.cloneElement(props.children, {
    ...props.children.props, 
    className: modifiedClassName
  });

  return(
    <>{modifiedChild}</>
  );
}
export default Button;