import React from "react"
import Layout from "../components/Layout/Layout"
import * as style from "../styles/form.module.scss"
import { TYPE_CLASSES } from "../components/FormHandler/FormHandler"
import Spinner from "../components/UI/Spinner/Spinner"
import Button from "../components/UI/Button/Button"
import FieldInfo from "../components/Formular/FieldInfo/FieldInfo"
import smoothscroll from 'smoothscroll-polyfill';

import AxiosInstance from "../components/Axios";
import Seo from "../components/SEO/SEO"

class Newsletter extends React.Component {
  state = {
    email: "",
    message: "",
    dsgvoConfimed: false,
    result: null,
    sendingData: false,
  }
  errorRef = React.createRef();

  componentDidMount(){
    smoothscroll.polyfill();
  }

  changeHandler = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleCheckbox = () => {
    this.setState(prevState => {
      return { dsgvoConfimed: !prevState.dsgvoConfimed }
    })
  }

  handleSubmit = e => {
    e.preventDefault()

    if (!this.state.dsgvoConfimed) {
      this.setState({
        result: "error",
        message: "Bitte bestätige die Datenschutzbestimmungen.",
      })
      this.errorRef.current.scrollIntoView({behavior: 'smooth'});
      return false;
    }

    this.setState({ sendingData: true });

    AxiosInstance.post('php/mailchimp.php', { email: this.state.email })      
    // Axios.post('http://localhost:8001/mailchimp.php', { email: this.state.email })      
      .then(({data}) => {
        let message = data.msg;
        let result = data.result;
        if(result === 'error') {
          message = `Folgender Fehler ist aufgetreten: ${message} 
            Sollte dieser Fehler bestehen bleiben, wende dich bitte an support@tiny-house-helden.de.`;
            this.errorRef.current.scrollIntoView({behavior: 'smooth'});
        }

        this.setState({
          result: result,
          message: message,
          sendingData: false,
        })
      })
      .catch(err => {
        // at this point unnecessary because Mailchimp only always returns a 200 status code
        // but: that could change ;)
        const emailHref = `mailto:support@tiny-house-helden.de?subject='${err}'`
        this.setState({
          result: "error",
          message: `Es ist folgender Fehler aufgetreten: <em>${err}</em><br>
                  Bitte wende dich an 
                  <a href="${emailHref}">support@tiny-house-helden.de</a>`,
          sendingData: false,
        })
      });
  }

  render() {
    const errorClass =
      this.state.result === "error" ? TYPE_CLASSES["error"] : "";
    // const params = this.props.pageContext.params ? this.props.pageContext.params : {}


    return (
      <>
      <Layout headlines={["Newsletter"]} className="centeredPage--TextPage">
        <h1>Bleib mit unserem Newsletter auf dem Laufenden!</h1>
        <p
          style={{
            display:
              this.state.result !== "error" && this.state.result
                ? "none"
                : "inline",
          }}
        >
          In unserem Newsletter informieren wir dich maximal einmal pro Woche
          über Neuigkeiten auf dieser Website. Das könnte z. B. das Hinzufügen
          neuer Hersteller oder Inhalte sein. Der Newsletter kann aber auch
          spannende Neuigkeiten zum Thema Tiny House enthalten – denn
          schließlich möchtest du nichts verpassen :) <br />
          <br />
        </p>
        <form className={style.Form} onSubmit={this.handleSubmit}>
          {this.state.sendingData ? <Spinner centered /> : null}
          <fieldset
            disabled={this.state.sendingData}
            style={{
              display: this.state.result === "success" ? "none" : null,
              opacity: this.state.sendingData ? "0.5" : "1",
            }}
          >
            <FieldInfo
              label={{ for: "email", text: "Deine Email-Adresse" }}
              className={style.hasFloatLabel}
            >
              <input
                className={TYPE_CLASSES["input"]}
                required
                type="email"
                name="email"
                value={this.state.email}
                onChange={this.changeHandler}
                placeholder=" "
              />
            </FieldInfo>

            <label className={style.labelCheckbox}>
              <input
                type="checkbox"
                name="dsgvo"
                defaultChecked={this.state.dsgvoConfimed}
                onChange={this.handleCheckbox}
              />
              Ja, ich möchte den Newsletter mit Neuigkeiten über die Website
              abonnieren und bin mit den Inhalten der{" "}
              <a target="_blank" href="/datenschutz/">
                Datenschutzerklärung
              </a>{" "}
              einverstanden.
            </label>

            <p className="fontSmall">
              Informationen zu der von deiner Einwilligung umfassten
              Erfolgsmessung, dem Einsatz des Newsletter-Anbieters "Mailchimp"
              und zu deinem Widerrufsrecht erhältst du unter dem Punkt
              "Datenschutz" auf dieser Website.
            </p>
            <Button color="black" type="narrow">
              <button type="submit">Für den Newsletter anmelden</button>
            </Button>
          </fieldset>
          <div className={style.ResponseMessageWrapper}>
            <p ref={this.errorRef}
              className={errorClass}
              dangerouslySetInnerHTML={{ __html: this.state.message }}
            />
          </div>
        </form>

       </Layout>
       </>
    )
  }
}
export default Newsletter

export function Head() { 

  return(
    <Seo
      title = 'Newsletter: bleib auf dem Laufenden! | Tiny House Helden'
      meta_description =
        {'Unser Newsletter informiert dich maximal einmal pro Woche über Neuigkeiten auf dieser Website – ' +
        ' z. B. über das Hinzufügen neuer Hersteller oder Inhalte.'}
    />
  )
}